import { template as template_b1cace0d4ee249b498a95a0fbf95e511 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b1cace0d4ee249b498a95a0fbf95e511(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
