import { template as template_a6be7f8fd3ed4fbe85f03e139ec2b628 } from "@ember/template-compiler";
const FKLabel = template_a6be7f8fd3ed4fbe85f03e139ec2b628(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
