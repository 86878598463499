import { template as template_54470b1a6bea4dc2a25c5caf5ab2d05d } from "@ember/template-compiler";
const FKText = template_54470b1a6bea4dc2a25c5caf5ab2d05d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
